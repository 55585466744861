<script>
import { ref, onMounted } from 'vue'
import copy from 'copy-text-to-clipboard'
import { http } from '@/http'
import { Toast } from 'vant'

export default {
  setup() {
    let qrcodeUrl = ref('')
    onMounted(() => {
      http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.getBrokerQrcodeUrl').then(res => {
        if(res.result) {
          qrcodeUrl.value = res.result
        }
      })
    })
    function handleCopy() {
      if(!qrcodeUrl.value) return
      copy(qrcodeUrl.value)
      Toast.success('推广链接复制成功')
    }
    return {
      handleCopy
    }
  }
}
</script>
<template>
  <div class="page">
    <div class="btn-area">
      <button @click="$router.push('/face-to-face')">面对面邀请</button>
      <button @click="handleCopy">链接邀请</button>
    </div>
    <div class="box-ct">
      <span>如何拿现金</span>
      <div>
        <img src="@/assets/images/icon-channel.png" />
        推荐好友成为渠道商
      </div>
    </div>
  </div>
</template>
<style scoped>
    .page { background-position: center top; padding-top: 10.6rem;background-size: 100%; background-image: url("~@/assets/images/bkgd1.png"); background-color: #f52a1e}
    .btn-area { text-align: center}
    .btn-area button {
      border: none;
      width: 3.46rem;
      height: 1.14rem;
      font-size: .38rem;
      color: #E12622;
      background-size: 100% 100%;
      background-color: transparent;
    }
    .btn-area button:nth-child(1) {
      margin-right: 3px;
      background-image: url("~@/assets/images/btn-bg.png");
    }
    .btn-area button:nth-child(2) {
      margin-left: 3px;
      background-image: url("~@/assets/images/btn-bg1.png");
    }
    .box-ct {
      box-sizing: border-box;
      width: 6.9rem;
      height: 2.74rem;
      background-size: 100%;
      margin: .8rem auto;
      background-image: url("~@/assets/images/box-bg.png");
    }
    .box-ct div { font-size: .3rem; padding:.64rem 0 0 .6rem }
    .box-ct span {display: block;color: #E12722; text-align: center; font-size: .36rem; padding-top:.25rem }
    .box-ct img { margin-right: .4rem; display: inline-block; vertical-align: middle;width:.92rem; height:.92rem }
</style>